import {OppWatchlistApp} from '@quantgate-systems/oppwatchlist';
import { useState } from 'react';


function App() {

  const queryParameters = new URLSearchParams(window.location.search);
  const bg = queryParameters.get("bg");
  const user = queryParameters.get("user");
  const pass = queryParameters.get("pass");

  const [symbols, setSymbols] = useState([]); //changed symbols sent as prop
  const [symbolsToRemove, setRemoveSymbols] = useState([]);

  const onItemClick = instrument => {
    console.log(instrument);
    SendMessageToWebview(instrument.symbol);
  };
  
  function SendMessageToWebview(symbol) {
      if (window.chrome.webview) // if contained in a webview
      {
        window.chrome.webview.postMessage(symbol);
      }
  }

  //your app's symbol handler (OPTIONAL)
  /* const handler = () => {
    setSymbols(prev => {
      return [...prev, newSymbolToAdd];
    });
  }; */

   /*  useEffect(() => {
    setTimeout(() => {
      //setSymbols(["IBM", "AAPL"]);
      addSymbolsHandler("GOOGL");
    }, 10000);

    setTimeout(() => {
      removeSymbolsHandler("MSFT");
    }, 12000);

  },[]); */

  /**@description Event handler to add new symbols */
  const addSymbolsHandler = (newSymbolsToAdd) => {
    setSymbols(prev => {
      return [...prev, newSymbolsToAdd];
    });
  }

  /**@description Event handler to remove symbols */
  const removeSymbolsHandler = (symbolsToRemove) => {
    setRemoveSymbols(prev => {
      return [...prev, symbolsToRemove];
    });
  }

  return (
    <OppWatchlistApp
      symbols={symbols}
      symbolsToRemove={symbolsToRemove}
      onSymbolsAdded={symbol => console.log(symbol)}
      onItemClick={onItemClick}
      //isSignalsOnly={false} //if true, then price labels will not be shown for Watchlist.
      miniFilter
      customCardBg={bg ? bg : 'black'}
      // customCardFg={'#252525'}
    />
  );
}

export default App;
